import React from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
interface StatusBarProps {
  status?: string;
}

const renderStep = (status: string, activStatus: string) => {
  return activStatus === status ? (
    <LinearGradient
      colors={["rgba(237, 117, 10, 1)", "rgba(200, 26, 92, 1)"]}
      style={styles.activeStep}
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 0 }}
    />
  ) : (
    <View style={styles.step} />
  );
};

const StatusBar: React.FC<StatusBarProps> = ({ status }) => {
  const { height } = Dimensions.get("window");
  const small = height < 500;

  const { t } = useTranslation();
  if (!status) return null;

  if (status === "refused") {
    return (
      <View style={styles.container}>
        <Text style={[styles.text, { fontSize: small ? 20 : 24 }]}>
          {t(status)}!
        </Text>
        <Text style={[styles.description, { marginTop: small ? 3 : 16 }]}>
          {t("refusedDescription")}
        </Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.stepperContainer}>
        {renderStep("new", status)}
        {renderStep("accepted", status)}
        {renderStep("done", status)}
        <View style={styles.line} />
      </View>
      <Text style={styles.text}>{t(status)}</Text>
    </View>
  );
};

export default StatusBar;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    marginTop: "37%",
    right: 0,
    left: 0,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#fff",
    fontFamily: "Montserrat-Bold",
    fontSize: 24,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  description: {
    color: "#fff",
    fontFamily: "Montserrat-Bold",
    fontSize: 16,
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 16,
    textAlign: "center",
  },
  stepperContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    zIndex: 1,
    width: 130,
    justifyContent: "space-between",
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  step: {
    backgroundColor: "#fff",
    height: 17,
    width: 17,
    borderRadius: 17,
  },
  line: {
    position: "absolute",
    top: 0,
    left: 0,
    marginLeft: 3,
    marginTop: 6.25,
    border: "solid 2px #fff",
    width: 120,
  },
  activeStep: {
    zIndex: 2,
    height: 23,
    width: 23,
    borderRadius: 23,
    marginTop: -3,
    animationName: "pulse",
  },
});
