// i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as RNLocalize from "react-native-localize";

const resources = {
  hu: { translation: require("./hu.json") },
  en: { translation: require("./en.json") },
};

const fallbackLng = "hu";

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,
  lng: RNLocalize.getLocales()[0].languageCode || fallbackLng,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
