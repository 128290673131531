import React from "react";
import { StyleSheet, Text, View, Dimensions } from "react-native";
import { IOrderStatusResponse, OrderStatusScreenProps } from "../helpers/type";
import { fetchOrderStatus } from "../helpers/order-service";
import OrderNumber from "../components/OrderNumber";
import StatusBar from "../components/StatusBar";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";

const OrderStatusScreen: React.FC<OrderStatusScreenProps> = ({ route }) => {
  const { t } = useTranslation();
  const { orderId } = route.params;
  const [orderStatus, setOrderStatus] = React.useState<IOrderStatusResponse>();
  const { update, isOpen } = useIntercom();
  const { height } = Dimensions.get("window");
  const small = height < 500;
  const medium = height >= 501 && height < 600;
  const large = height >= 601 && height < 700;
  const xlarge = height >= 701;

  const handleFetchContacts = (id: string) => {
    fetchOrderStatus(id)
      .then((data) => {
        if (data) {
          setOrderStatus(data);
        }
      })
      .finally();
  };

  React.useEffect(() => {
    const fetchData = () => {
      if (orderId) {
        handleFetchContacts(orderId);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [orderId]);

  React.useEffect(() => {
    if (orderStatus && !isOpen) {
      update({
        userId: orderStatus.uuid,
        email: orderStatus.status,
        phone: String(orderStatus.order_id),
        utmContent: orderStatus.logo,
        utmTerm: orderStatus.balena_uuid,
        utmSource: orderStatus.device_name,
      });
    }
  }, [orderStatus]);

  return (
    <Layout>
      <StatusBar status={orderStatus?.status} />
      <OrderNumber
        number={orderStatus?.order_id || 0}
        logo={orderStatus?.logo || require("../assets/burger-king.png")}
      />
      <View style={styles.container}>
        {orderStatus?.status === "done" && (
          <Text
            style={[
              styles.description,
              { marginBottom: 12, fontSize: medium || small ? 14 : 20 },
            ]}
          >
            {t("readyDescription")}
          </Text>
        )}
        {orderStatus?.status === "done" && (
          <Text
            style={[
              styles.description,
              {
                marginBottom: xlarge ? "45%" : large ? "35%" : "25%",
                fontSize: medium || small ? 14 : 20,
              },
            ]}
          >
            {t("enjoy")}
          </Text>
        )}
      </View>
    </Layout>
  );
};

export default OrderStatusScreen;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  description: {
    width: "100%",
    fontFamily: "Montserrat-Bold",
    color: "#fff",
    textAlign: "center",
    paddingHorizontal: 16,
  },
});
