import React from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  Text,
  Image,
  StyleSheet,
  ImageSourcePropType,
  Dimensions,
} from "react-native";

interface OrderNumberProps {
  number: number;
  logo: string;
}

const OrderNumber: React.FC<OrderNumberProps> = ({ number, logo }) => {
  const { t } = useTranslation();
  const ImageURL = "https://img.menu.foodiest.ai/sign/";

  const { height } = Dimensions.get("window");
  const small = height < 500;

  return (
    <>
      <Text style={styles.serialNumber}>{t("orderNumber")}</Text>
      <View
        style={[
          styles.container,
          {
            height: small ? 60 : 80,
            maxWidth: small ? 182 : 224,
          },
        ]}
      >
        <View style={{ height: small ? 42 : 64, width: small ? 42 : 64 }}>
          <Image
            style={{ height: small ? 42 : 64, width: small ? 42 : 64 }}
            source={
              `${ImageURL}rs:fill:279:314/${btoa(logo)}` as ImageSourcePropType
            }
          />
        </View>
        <Text style={[styles.orderNumber, { fontSize: small ? 36 : 56 }]}>
          {number}
        </Text>
      </View>
    </>
  );
};

export default OrderNumber;

const styles = StyleSheet.create({
  serialNumber: {
    fontFamily: "Montserrat-Bold",
    color: "#fff",
    fontSize: 16,
    marginBottom: 14,
    marginTop: 50,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    boxShadow: "0px 8px 0px 0px rgba(150, 134, 156, 0.60)",
    borderRadius: 30,
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 28,
  },
  logoContainer: { width: 64, height: 64 },
  logo: {
    height: 64,
    width: 64,
  },
  orderNumber: {
    fontFamily: "Montserrat-Regular",
    color: "#B71278",
    paddingLeft: 12,
  },
});
